<template>
  <div class="auth-wrap">
    <!-- 三要素认证弹窗 -->
    <el-dialog :visible.sync="dialogVisible" :before-close="handleClose" :close-on-click-modal="false" width="540px"
      custom-class="auth-dialog">
      <template slot="title">
        <div class="title">
          <img src="@/assets/img/auth.png" alt="" />
          三要素认证
        </div>
      </template>

      <div class="auth-form-box">
        <!-- <div class="success-box" v-if="isSuccess">
          <img src="@/assets/img/idCard.png" alt="" />
          <h1>恭喜你</h1>
          <h2>实名认证成功</h2>
        </div> -->

        <div class="auth-phone" v-if="userInfo">
          <div class="ps">* 请确认您平台绑定{{ userInfo.Phone }}手机号的实名和实名认证的信息一致，再提交验证</div>

          <el-form :model="ruleForm2" :rules="rules2" ref="ruleForm2" label-width="110px">
            <!-- 换绑手机号 -->
            <div v-if="showBindPhone">
              <el-form-item label="手机号：" prop="Phone">
                <el-input v-model.trim="ruleForm2.Phone" placeholder="请输入手机号"></el-input>
              </el-form-item>

              <el-form-item label="验证码：" prop="Code">
                <el-input v-model.trim="ruleForm2.Code" placeholder="请输入验证码"></el-input>
                <el-button type="primary" class="change-phone">获取验证码</el-button>
              </el-form-item>

              <el-form-item>
                <el-button type="primary" @click="submitForm('ruleForm2')" round>确认换绑手机号</el-button>
              </el-form-item>
            </div>
            <!-- 已绑定手机号 -->
            <div v-else>
              <el-form-item label="手机号：">
                <el-input v-model.trim="userInfo.Phone" disabled placeholder="请输入手机号"></el-input>
                <el-button type="primary" class="change-phone" @click="showBindPhone = true">修改手机号</el-button>
              </el-form-item>
            </div>

          </el-form>
        </div>

        <div>
          <div class="title">
            <span class="text-strike">实名信息</span>
          </div>
          <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="110px">
            <el-form-item label="真实姓名：" prop="RealName">
              <el-input v-model.trim="ruleForm.RealName" placeholder="请输入真实姓名"></el-input>
            </el-form-item>
            <el-form-item label="身份证号码：" prop="IDCard">
              <el-input v-model.trim="ruleForm.IDCard" placeholder="请输入身份证号码"></el-input>
            </el-form-item>
            <el-form-item label="">
              <div class="ps">* 请输入实名信息提交三要素认证</div>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="submitForm('ruleForm')" round>提交三要素认证</el-button>
            </el-form-item>
          </el-form>

        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { authentication } from "@/api/index";
import cMethods from "@/util/cMethods.js";

export default {
  props: {
    updateVisible: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters(["userInfo", "getIsAuth"]),
  },
  data() {
    var validateName = (rule, value, callback) => {
      if (value && value !== "") {
        let regEn = /^[\u4e00-\u9fa5]{2,4}$/;
        if (!regEn.test(value)) {
          callback(new Error("真实姓名填写有误"));
        } else {
          callback();
        }
      } else {
        callback();
      }
    };
    var validateIdCard = (rule, value, callback) => {
      if (value && value !== "") {
        let regEn = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
        if (!regEn.test(value)) {
          callback(new Error("身份证号填写有误"));
        } else {
          callback();
        }
      } else {
        callback();
      }
    };
    return {
      showBindPhone: false,
      checked: false,
      dialogVisible: false,
      ruleForm: {
        // RealName: "黄伊慧",
        // IDCard: "330323198203191841",
        RealName: "",
        IDCard: "",
      },
      ruleForm2: {
        Phone: "",
        Code: ""
      },
      isSuccess: false,
      rules: {
        RealName: [
          { required: true, message: "请输入真实姓名", trigger: "blur" },
          // { validator: validateName, trigger: "blur" },
        ],
        IDCard: [
          { required: true, message: "请输入身份证号码", trigger: "blur" },
          { validator: validateIdCard, trigger: "blur" },
        ],
      },
      rules2: {
        Phone: [
          { required: true, message: "请输入手机号", trigger: "blur" },
        ],
        Code: [
          { required: true, message: "请输入验证码", trigger: "blur" },
        ]
      }
    };
  },
  methods: {
    show() {
      this.dialogVisible = true;
    },
    hide() {
      this.dialogVisible = false;
    },
    handleClose(done) {
      this.showBindPhone = false;
      this.hide();
    },
    // 实名认证表单提交
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.checked) {
            this._authentication();
          } else {
            this.$message({
              type: "error",
              message: "请阅读并同意免费玩协议",
            });
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    async _authentication() {
      let params = {
        ConsumerId: this.userInfo.ConsumerId,
        RealName: this.ruleForm.RealName,
        IDCard: this.ruleForm.IDCard,
      };
      let res = await authentication(params);
      if (res.StatusCode == 200) {
        this.$message({
          type: "success",
          message: "实名认证成功",
        });
        this.$store.dispatch("_GetUserInfo");
        this.hide();

        // 截图、父组件启动游戏
        let phone = sessionStorage.getItem("Phone");
        if (phone) {
          cMethods.FormAliScreenShot(phone);
          this.$parent.seniorJDQS(phone);
        }
      }
    },
    toSenior() {
      if (this.checked) {
        this.hide();
        // 截图、父组件启动游戏
        let phone = sessionStorage.getItem("Phone");
        if (phone) {
          cMethods.FormAliScreenShot(phone);
          this.$parent.seniorJDQS(phone);
        }
      } else {
        this.$message({
          type: "error",
          message: "请阅读并同意免费玩协议",
        });
      }
    },
  },
  watch: {
    // 监听 updateVisible 改变
    updateVisible(oldVal, newVal) {
      this.dialogVisible = this.updateVisible;
    },
  },
};
</script>

<style lang="scss" scoped>
.auth-dialog {
  .title {
    font-size: 16px;
    font-weight: bold;
    @include font_col3();

    img {
      width: 24px;
      height: 25px;
      margin-right: 10px;
    }
  }

  .auth-info {
    font-size: 16px;

    label {
      width: 100px;
      display: inline-block;
      @include font_col3();
    }

    span {
      @include font_col9();
    }

    .el-divider--horizontal {
      margin: 20px 0;
    }
  }

  .auth-form-box {
    @include font_col3();

    .ps {
      margin-bottom: 20px;
      line-height: 26px;
      color: #ff3636;
    }

    .auth-phone {

      // margin-bottom: 20px;
      .change-phone {
        margin-left: 10px;
      }
    }

    p {
      line-height: 26px;
      margin-bottom: 20px;
      @include font_col6();
    }

    .el-input {
      width: 240px;
    }

    .title {
      margin: 20px 0;
      text-align: center;

      .text-strike {
        position: relative;
        color: #333;
      }

      .text-strike::before,
      .text-strike::after {
        content: '';
        position: absolute;
        top: 50%;
        width: 200%;
        height: 1px;
        background: #333;
      }

      .text-strike::before {
        left: -110%;
        transform: translateX(-50%);
      }

      .text-strike::after {
        right: -110%;
        transform: translateX(50%);
      }
    }

    .success-box {
      line-height: 30px;
      text-align: center;

      h1 {
        margin-top: 10px;
        font-size: 26px;
        font-weight: 500;
      }

      h2 {
        margin: 10px 0 8px 0;
        font-size: 22px;
        font-weight: 500;
      }
    }

    // .dialog-footer {
    //   width: 100%;
    //   display: flex;

    //   .el-button {
    //     width: 200px;
    //     margin: 0 auto;
    //   }
    // }
  }
}
</style>